import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId: 0,
  firstName: '',
  lastName: '',
  image: '',
  email:'',
  roleName:'',
  roleId:'',
  address1:'',
  address2:'',
  city:'',
  state:'',
  zipCode:'',
  contact:'',
  isActive: '',
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (states, action) => {
      const { userId, firstName, lastName, image ,email, roleName, address1, address2, city, state, zipCode, contact, roleId, isActive} = action.payload;
      states.userId = userId;
      states.firstName = firstName;
      states.lastName = lastName;
      states.image = `data:image/jpeg;base64,${image}`;
      states.email=email;
      states.roleName=roleName;
      states.address1=address1;
      states.address2=address2
      states.city=city;
      states.state=state;
      states.zipCode=zipCode;
      states.roleId=roleId;
      states.contact=contact;
      states.isActive=isActive;
    },
  }
});

export const { setUser } = UserSlice.actions;

export default UserSlice.reducer;
