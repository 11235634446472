import api from "../Configuration/ApiConfig";

const ReportsService = {
  GetTodayVanStops: (vehicle, selectedDate, endDate) => {
    const request = api.post(`Reports/GetTodayVanStops?id=${vehicle}&startDate=${selectedDate}&endDate=${endDate}`);
    return request.then((response) => {
      return response.data;
    });
  },
  PrintTodayVanStops: async (ids, selectedDate, endDate) => {
    try {
      const url = selectedDate !== null ? `Reports/PrintTodayVanStops?startDate=${selectedDate}&endDate=${endDate}` : 'Reports/PrintTodayVanStops';
      const response = await api.post(
        url,
        ids,
        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting document:", error.message);
      return null;
    }
  },
  GetPickList: (vehicle, selectedDate, endDate) => {
    const request = api.post(`Reports/GetPickList?id=${vehicle}&startDate=${selectedDate}&endDate=${endDate}`);
    return request.then((response) => {
      return response.data;
    });
  },
  PrintPickList: async (ids, selectedDate, endDate) => {
    try {
      const url = selectedDate !== null ? `Reports/PrintPickList?startDate=${selectedDate}&endDate=${endDate}` : 'Reports/PrintPickList';
      const response = await api.post(
        url,
        ids,
        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting document:", error.message);
      return null;
    }
  },
  PrintInventoryReport: async (item) => {
    try {
      const response = await api.get(
        `Reports/GenerateInventoryReport?categoryId=${item}`,

        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting document:", error.message);
      return null;
    }
  },
  GetItemSalesAndPurchasesUnitsByDate: (filter) => {
    const request = api.post('Reports/GetItemSalesAndPurchasesUnitsByDate', filter);
    return request.then((response) => {
      return response.data;
    });
  },
  GetItemSalesAndPurchasesUnitsByDateReport: async (filter) => {

    try {
      const response = await api.post(
        `Reports/GetItemSalesAndPurchasesUnitsByDateReport`, filter,

        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting document:", error.message);
      return null;
    }
  },
  GetInventory: (filter) => {
    const request = api.post('Reports/GetInventory', filter);
    return request.then((response) => {
      return response.data;
    });
  },
  GetInventoryReport: async (filter) => {

    try {
      const response = await api.post(
        `Reports/GetInventoryReport`, filter,

        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting document:", error.message);
      return null;
    }
  },
  GetSalesOrderGrossProfit: (filter) => {
    const request = api.post('Reports/GetSalesOrderGrossProfit', filter);
    return request.then((response) => {
      return response.data;
    });
  },
  GetSalesOrderGrossProfitReport: async (filter) => {

    try {
      const response = await api.post(
        `Reports/GetSalesOrderGrossProfitReport`, filter,

        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting document:", error.message);
      return null;
    }
  },
  GetSalesOrderAndPaymentDetail: (filter) => {
    const request = api.post('Reports/GetSalesOrderAndPaymentDetail', filter);
    return request.then((response) => {
      return response.data;
    });
  },
  GetSalesOrderAndPaymentDetailReport: async (filter) => {
    try {
      const response = await api.post(
        `Reports/GetSalesOrderAndPaymentDetailReport`, filter,

        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting document:", error.message);
      return null;
    }
  },
  GetPurchaseOrderAndPaymentDetail: (filter) => {
    const request = api.post('Reports/GetPurchaseOrderAndPaymentDetail', filter);
    return request.then((response) => {
      return response.data;
    });
  },
  GetPurchaseOrderAndPaymentDetailReport: async (filter) => {
    try {
      const response = await api.post(
        `Reports/GetPurchaseOrderAndPaymentDetailReport`, filter,

        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting document:", error.message);
      return null;
    }
  },
  SendCustomerStatement: (filter, message) => {
    const request = api.post("Reports/SendCustomerStatement", filter, {
      params: { message: message }});
    return request.then((response) => {
      return response.data;
    }).catch((error) => {
      console.error("There was an error sending the email:", error);
    });
  },
  GetSalesTransactionReport: (filter) => {
    const request = api.post('Reports/GetSalesTransactionReport', filter);
    return request.then((response) => {
      return response.data;
    });
  },
  GetTaxReport: (filter) => {
    const request = api.post('Reports/GetTaxReport', filter);
    return request.then((response) => {
      return response.data;
    });
  },
  GetPurchaseTransactionReport: (filter) => {
    const request = api.post('Reports/GetPurchaseTransactionReport', filter);
    return request.then((response) => {
      return response.data;
    });
  },
};

export default ReportsService;
