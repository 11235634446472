import React from 'react'
import { Watch } from "react-loader-spinner";
import  Backdrop from '@mui/material/Backdrop';
export default function Lodder({ show }) {
    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={show}
        >
            <Watch
                visible={true}
                height="80"
                width="80"
                radius="48"
                color="#1e56a0"
                ariaLabel="watch-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </Backdrop>
    )
}
