import api from "../Configuration/ApiConfig";

const ItemCategoryService = {
  GetItemCategoryList: () => {
    const request = api.get("ItemCategory/GetItemCategoryList");
    return request.then((response) => {
      return response.data;
    });
  },

  AddItemCategory: (itemCategoryData) => {
    const request = api.post("ItemCategory/CreateItemCategory", itemCategoryData);
    return request.then((response) => {
      return response.data;
    });
  },

  DeleteItemCategory: (id) => {
    const request = api.delete("ItemCategory/DeleteItemCategory?id=" + id);
    return request.then((response) => {
      return response.data;
    });
  },

  UpdateItemCategory: (itemCategoryData) => {
    const request = api.put("ItemCategory/UpdateItemCategory",itemCategoryData);
    return request.then((response) => {
      return response.data;
    });
  },
};

export default ItemCategoryService;
