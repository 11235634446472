import { useState, useEffect } from "react";
import DatePickerComponent from "../Common/UI-Components/DatePicker";
import Lodder from "../Loader";
import CustomSelect from "../Common/UI-Components/CustomSelect";
import ItemCategoryService from "../../API/ItemCategoryService";
import ReportsService from "../../API/ReportsService";
import { Table, Button, Tooltip } from "antd";
import ExportDropdown from "../Common/UI-Components/ExportButton";
import moment from "moment";
import { subMonths, format, parseISO } from "date-fns";
import DateRangePicker from "../Common/UI-Components/DateRangeSelector";

const MonthlyInvoices = () => {
    const [showlodder, setShowlodder] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();
    const [itemList, setItemList] = useState([]);
    const [itemCategoryList, setItemCategoryList] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState(3);
    const [itemsList, setItemsList] = useState([]);
    const [item, setItem] = useState("All Categories");
    const [data, setData] = useState({ categoryId: "", startDate: "", endDate: "", paymentStatus: 3 });

    useEffect(() => {
        ItemCategoryService.GetItemCategoryList()
            .then((response) => {
                if (response.status) {
                    setItemsList(response.resultData);
                    const itemCategoryListWithId = response.resultData.map((itemCategory) => ({
                        value: itemCategory.itemCategoryId,
                        label: itemCategory.name
                    }));
                    itemCategoryListWithId.unshift({ value: null, label: "All Categories" });
                    setItemCategoryList(itemCategoryListWithId);
                }
            })
            .finally(() => { });
    }, []);

    useEffect(() => {
        if (itemCategoryList.length > 0) {
            setDefault();
        }
    }, [itemCategoryList]);

    useEffect(() => {
        if (data.startDate != "") {
            getReport(data);
        }
    }, [data]);

    const setDefault = () => {
        const currentDate = new Date();
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() -1, 1);
        const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        setSelectedStartDate(parseISO(formattedStartDate));
        setSelectedEndDate(parseISO(formattedEndDate));
        setPaymentStatus(3);
        setItem("All Categories");
        setData({ categoryId: "", startDate: formattedStartDate, endDate: formattedEndDate, paymentStatus: 3 });

    };

    const formatDate = (date) => {
        return format(date, 'yyyy-MM-dd');
    };


    const handleChangeCategory = (value) => {
        setItem(value);
        setData({ ...data, categoryId: value });
    };

    const handleDateChange = (startDate, endDate) => {
        const formattedStartDate = startDate ? formatDate(startDate) : '';
        const formattedEndDate = endDate ? formatDate(endDate) : '';
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
        setData(prevFilter => ({ ...prevFilter, startDate: formattedStartDate, endDate: formattedEndDate }));
    };
    // const handleChangeStartDate = (date) => {
    //     setStartDate(date);
    //     const formattedDate = formatDate(date);
    //     setData({ ...data, startDate: formattedDate });
    // };

    // const handleChangeEndDate = (date) => {
    //     setEndDate(date);
    //     const formattedDate = formatDate(date);
    //     setData({ ...data, endDate: formattedDate });
    // };

    const handleChangePaymentStatus = (value) => {
        setPaymentStatus(value);
        setData({ ...data, paymentStatus: value });
    };

    const clearFilter = () => {
        setItem("All Categories");
        setDefault();
    };


    const getReport = () => {
        ReportsService.GetSalesOrderGrossProfit(data).then((response) => {
            if (response.status) {
                const itemListWithId = response.resultData.map((item, index) => ({
                    ...item,
                    id: index + 1
                }));
                setItemList(itemListWithId);
            }
        })
            .finally(() => {
            });
    }

    const invoiceTotal = itemList?.reduce((sum, item) => sum + item.invoiceTotal, 0);
    const itemTotal = itemList?.reduce((sum, item) => sum + item.itemsTotal, 0);
    const profit = itemList?.reduce((sum, item) => sum + item.grossProfit, 0);
    const tax = itemList?.reduce((sum, item) => sum + item.taxAmount, 0);


    const printData = () => {
        setShowlodder(true);
        ReportsService.GetSalesOrderGrossProfitReport(data)
            .then((response) => {
                const blob = new Blob([response], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = url;
                document.body.appendChild(iframe);

                iframe.onload = () => {
                    const printWindow = window.open('', '_blank');
                    if (printWindow) {
                        printWindow.document.write('<html><head><title>Print Preview</title></head><body>');
                        printWindow.document.write('<embed width="100%" height="100%" name="plugin" src="' + url + '" type="application/pdf" internalinstanceid="21">');
                        printWindow.document.write('</body></html>');
                        printWindow.document.close();
                        printWindow.onload = () => {
                            printWindow.focus();
                            // printWindow.print();
                        };
                        setShowlodder(false);
                    } else {
                        console.error('Failed to open print window');
                        setShowlodder(false);
                    }
                    setTimeout(() => {
                        document.body.removeChild(iframe);
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                };
                setShowlodder(false);

            })
            .finally(() => {
                setShowlodder(false);
            });
    }

    const columns = [
        {
            title: "Order ID",
            dataIndex: "salesOrderId",
            key: "salesOrderId",
            sorter: (a, b) => a.salesOrderId - b.salesOrderId,
        },
        {
            title: "Order Date",
            dataIndex: "orderDate",
            key: "orderDate",
            sorter: (a, b) => (a.orderDate && b.orderDate) ? a.orderDate.localeCompare(b.orderDate) : 0,
            render: (orderDate) => {
                return (
                    <>
                        {new Date(orderDate).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                        })}
                    </>
                );
            },
        },
        {
            title: "Customer",
            dataIndex: "firstName",
            key: "firstName",
            sorter: (a, b) =>
                a.firstName && b.firstName ? a.firstName.localeCompare(b.firstName) : 0,
        },
        {
            title: "Category",
            dataIndex: "categoryName",
            key: "categoryName",
            sorter: (a, b) =>
                a.categoryName && b.categoryName ? a.categoryName.localeCompare(b.categoryName) : 0,
        },
        {
            title: "Invoice Total",
            dataIndex: "invoiceTotal",
            key: "invoiceTotal",
            align: 'right',
            sorter: (a, b) => a.invoiceTotal - b.invoiceTotal,
            render: (text) => {
                const amount = Number(text);
                return `$${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            }
        },
        {
            title: "Tax",
            dataIndex: "taxAmount",
            key: "taxAmount",
            align: 'right',
            sorter: (a, b) => a.taxAmount - b.taxAmount,
            render: (text) => {
                const amount = Number(text);
                return `$${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            }
        },
        {
            title: "Cost Of Goods Sold",
            dataIndex: "itemsTotal",
            key: "itemsTotal",
            align: 'right',
            sorter: (a, b) => a.itemsTotal - b.itemsTotal,
            render: (text) => {
                const amount = Number(text);
                return `$${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            }
        },
        {
            title: "Gross Profit",
            dataIndex: "grossProfit",
            key: "grossProfit",
            align: 'right',
            sorter: (a, b) => a.grossProfit - b.grossProfit,
            render: (text) => {
                const amount = Number(text);
                return `$${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            }
        },
    ];


    const exportColumns = [
        { label: 'Order ID', key: 'salesOrderId' },
        { label: 'Order Date', key: 'orderDate' },
        { label: 'Customer', key: 'firstName' },
        { label: 'Category', key: 'categoryName' },
        { label: 'Invoice Total', key: 'invoiceTotal' },
        { label: 'Tax', key: 'taxAmount' },
        { label: 'Cost Of Goods Sold', key: 'itemsTotal' },
        { label: 'Gross Profit', key: 'grossProfit' },
    ];

    const filteredOrders = itemList.map(order => ({
        orderId: order.orderId,
        orderDate: moment(order.orderDate).format('MMM D, YYYY'),
        firstName: order.firstName,
        categoryName: order.categoryName,
        invoiceTotal: "$" + order.invoiceTotal?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2}),
        itemsTotal: "$" + order.itemsTotal?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2}),
        grossProfit: "$" + order.grossProfit?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2}),
        taxAmount: "$" + order.taxAmount?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2})
    }));


    return (
        <div className="col-lg-12" style={{ marginTop: "30px" }}>
            <Lodder show={showlodder} />
            <div className="d-xxl-flex justify-content-between mb-3">
                <div className="d-sm-flex mt-2">
                    <div className="search-box mt-2 mt-md-0">
                        <CustomSelect
                            onChange={(value) => {
                                handleChangeCategory(value);
                            }}
                            placeholder="Select Category"
                            value={item}
                            options={itemCategoryList}
                        />
                    </div>
                    <div className="me-3">
                        <DateRangePicker
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                            onDateChange={handleDateChange}
                        />
                    </div>
                    <div className="search-box mt-2 mt-md-0 ms-2">
                        <CustomSelect
                            value={paymentStatus}
                            options={[
                                { value: 2, label: "Open" },
                                { value: 1, label: "Partial-Paid" },
                                { value: 0, label: "Paid" },
                                { value: 3, label: "All Statuses" },
                            ]}
                            placeholder="Select Payment Status"
                            onChange={(value) => {
                                handleChangePaymentStatus(value);
                            }}
                        />
                    </div>
                    <button type="submit" className="btn btn-outline-secondary mt-2 mt-md-0" onClick={clearFilter}>
                        CLEAR FILTERS
                    </button>
                </div>
            </div>
            <div className="card border-0">
                <div className="card-header border-0 bg-white py-2 d-flex justify-content-between align-items-center">
                    <h2 className="card-title mb-0">Profit and Cost</h2>
                    <div className="d-md-flex">
                        <Tooltip title="Print">
                            <button type="submit" className="btn btn-primary w-20 mt-2 mt-xl-0" onClick={printData}>
                                <i className="fa-solid fa-print pointer"></i>
                            </button>
                        </Tooltip>
                        <div className="ms-3 w-20 mt-2 mt-md-0">
                            <ExportDropdown
                                filename="Profit-Cost"
                                exportColumns={exportColumns}
                                data={filteredOrders}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-body px-0 pt-0 pb-0">
                    <div className="table-responsive">
                        <Table
                            className="table table-hover table-striped"
                            dataSource={itemList}
                            columns={columns}
                            pagination={{
                                showSizeChanger: true,
                                pageSizeOptions: ['5', '10', '20', '50', '100'],
                                defaultPageSize: 10,
                                total: itemList.length,
                                showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                itemRender: (current, type, originalElement) => {
                                    if (type === 'prev') {
                                        return <Button>Previous</Button>;
                                    }
                                    if (type === 'next') {
                                        return <Button>Next</Button>;
                                    }
                                    return originalElement;
                                },
                            }}
                            rowKey="id"
                            summary={() => (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell colSpan={4} align="right">
                                        <strong>Total:</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell align="right">
                                        <strong>${invoiceTotal?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell align="right">
                                        <strong>${tax?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell align="right">
                                        <strong>${itemTotal?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell align="right">
                                        <strong>${profit?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MonthlyInvoices;