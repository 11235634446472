import { Link, useNavigate, useLocation } from "react-router-dom";
import UserService from "../../API/UserService";
import { setUser } from "../State/User/UserSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import CustomModal from "../Common/CustomModal/CustomModal";
import Lodder from "../Loader";
import Breadcrumb from "../Common/UI-Components/BreadCrumb";

const AppLayout = ({ children }) => {
  const dispatch = useDispatch();
  const [showlodder, setShowlodder] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInventoryMenuOpen, setIsInventoryMenuOpen] = useState(false);
  const [isFinanceMenuOpen, setIsFinanceMenuOpen] = useState(false);
  const [isSetupMenuOpen, setIsSetupMenuOpen] = useState(false);
  const [isPurchasingMenuOpen, setIsPurchasingMenuOpen] = useState(false);
  const [isSalesMenuOpen, setIsSalesMenuOpen] = useState(false);
  const [isReportingMenuOpen, setIsReportingMenuOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  const navigation = location.pathname;

  const navigate = useNavigate();

  const openModal = () => {
    setVisible(true);
  };

  const handleModelCancelButton = () => {
    setVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userRole");
    navigate("/");
  };

  const handleReportingMenuToggle = () => {
    setIsReportingMenuOpen(!isReportingMenuOpen);
    setIsInventoryMenuOpen(false);
    setIsSetupMenuOpen(false);
    setIsPurchasingMenuOpen(false);
    setIsSalesMenuOpen(false);
    setIsFinanceMenuOpen(false);
    setIsMenuOpen(false);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsInventoryMenuOpen(false);
    setIsSetupMenuOpen(false);
    setIsPurchasingMenuOpen(false);
    setIsSalesMenuOpen(false);
    setIsReportingMenuOpen(false);
    setIsFinanceMenuOpen(false);
  };
  const handleInventoryMenuToggle = () => {
    setIsInventoryMenuOpen(!isInventoryMenuOpen);
    setIsSetupMenuOpen(false);
    setIsPurchasingMenuOpen(false);
    setIsSalesMenuOpen(false);
    setIsFinanceMenuOpen(false);
    setIsMenuOpen(false);
    setIsReportingMenuOpen(false);
  };
  const handleSetupMenuToggle = () => {
    setIsSetupMenuOpen(!isSetupMenuOpen);
    setIsInventoryMenuOpen(false);
    setIsPurchasingMenuOpen(false);
    setIsSalesMenuOpen(false);
    setIsFinanceMenuOpen(false);
    setIsReportingMenuOpen(false);
    setIsMenuOpen(false);
  };
  const handlePurchasingMenuToggle = () => {
    setIsPurchasingMenuOpen(!isPurchasingMenuOpen);
    setIsSalesMenuOpen(false);
    setIsFinanceMenuOpen(false);
    setIsMenuOpen(false);
    setIsReportingMenuOpen(false);
    setIsInventoryMenuOpen(false);
    setIsSetupMenuOpen(false);
  };
  const handleSalesMenuToggle = () => {
    setIsSalesMenuOpen(!isSalesMenuOpen);
    setIsFinanceMenuOpen(false);
    setIsMenuOpen(false);
    setIsReportingMenuOpen(false);
    setIsInventoryMenuOpen(false);
    setIsSetupMenuOpen(false);
    setIsPurchasingMenuOpen(false);
  };


  useEffect(() => {
    setShowlodder(true);
    UserService.GetUser()
      .then((response) => {
        if (response.status) {
          dispatch(setUser(response.resultData));
          setShowlodder(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setShowlodder(false);
      });
  }, []);

  return (
    <>
      <div className="wrapper">
        <Lodder show={showlodder} />
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <i className="fas fa-bars" />
              </a>
            </li>
            <li className="nav-item">
              {/* <Breadcrumb/> */}
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <img
                  src={user.image}
                  alt="user"
                  className="img-fluid img-round me-3"
                />
                <span className="drop-title">
                  {user.firstName} {user.lastName}
                </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-right"
                style={{ left: "inherit", right: 0 }}
              >
                <Link to="/profile" className="dropdown-item">
                  Profile
                </Link>
                <a onClick={() => openModal()} className="dropdown-item">
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </nav>

        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <a href="#" className="brand-link text-decoration-none head">
            <h2 className="brand-text mt-1 first-img">
              Yiannis
              <span className="brand-subtext d-block">Foods and Wines</span>
            </h2>
            {/* <h3 className="second-img">YFW</h3> */}
          </a>
          <div className="sidebar">
            <ul
              className="ps-0  nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
              style={{ listStyle: "none" }}
            >
              <li className="nav-item">
                <h6 className="menu mt-2">Menu</h6>
              </li>
              <li className="nav-item">
                <Link to="/home" className={`nav-link ${navigation === "/home" ||
                  navigation === "*"
                  ? "active"
                  : ""
                  } `}>
                  <i className="fa-solid fa-house-chimney-window me-3"></i>
                  <p className="">Home</p>
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  to="/dashboard"
                  className={`nav-link ${navigation === "/dashboard" ||
                    navigation === "/Dashboard"
                    ? "active"
                    : ""
                    } `}
                >
                  <i className="fa-solid fa-gauge me-3" />
                  <p className="">Dashboard</p>
                </Link>
              </li>

              <li className={`nav-item ${isSalesMenuOpen ? "menu-open" : ""}`}>
                <Link
                  className={`nav-link ${navigation === "/customers" ||
                    navigation === "/add-customer" ||
                    navigation === "/customer-detail" ||
                    navigation.startsWith("/update-customer/") ||
                    navigation.startsWith("/customer-detail/") ||
                    navigation === "/invoices" ||
                    navigation.startsWith("/invoice-detail/") ||
                    navigation.startsWith("/add-invoice")
                    ? "active"
                    : ""
                    } `}
                  onClick={handleSalesMenuToggle}
                >
                  <i className="fa-solid fa-store me-3" />
                  <p className="">Sales</p>
                  <i className="right fas fa-angle-right" />
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/invoice-detail"
                      className={`nav-link ${navigation === "/invoice-detail" ||
                        navigation.startsWith("/invoice-detail/")
                        ? "active" : ""
                        } `}
                    >
                      <i className={`${navigation === "/invoice-detail" ||
                        navigation.startsWith("/invoice-detail/")
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>Invoice Detail</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/invoices"
                      className={`nav-link ${navigation.startsWith("/invoice") || navigation.startsWith("/add-invoice") 
                        || navigation.startsWith("/update-invoice/") ? "active" : ""
                        } `}
                    >
                      <i className={`${navigation.startsWith("/invoices") || navigation.startsWith("/add-invoice") || navigation.startsWith("/update-invoice/")
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p className="">Invoices</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/customer-detail"
                      className={`nav-link ${navigation.startsWith("/customer-detail/") ||
                        navigation === "/customer-detail"
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation.startsWith("/customer-detail/") ||
                        navigation === "/customer-detail"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p className="">Customer Detail</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/customers"
                      className={`nav-link ${navigation === "/customers" ||
                        navigation === "/add-customer" ||
                        navigation.startsWith("/update-customer/")
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className="fa-solid fa-users me-3" />
                      <p className="">Customers</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className={`nav-item ${isPurchasingMenuOpen ? "menu-open" : ""
                  }`}
              >
                <Link
                  className={`nav-link ${navigation === "/vendors" ||
                    navigation === "/add-vendor" ||
                    navigation.startsWith("/update-vendor/") ||
                    navigation.startsWith("/vendor-detail/") ||
                    navigation === "/purchase-orders" ||
                    navigation.startsWith("/purchase-order-detail/") ||
                    navigation.startsWith("/update-purchase-order/") ||
                    navigation.startsWith("/add-purchase-order")
                    ? "active"
                    : ""
                    } `}
                  onClick={handlePurchasingMenuToggle}
                >
                  <i className="fa-solid fa-cart-shopping me-3" />
                  <p className="">Purchasing</p>
                  <i className="right fas fa-angle-right" />
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/purchase-order-detail"
                      className={`nav-link ${navigation === "/purchase-order-detail" ||
                        navigation.startsWith("/purchase-order-detail/")
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/purchase-order-detail" ||
                        navigation.startsWith("/purchase-order-detail/")
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>Purchase Order Detail</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/purchase-orders"
                      className={`nav-link ${navigation === "/purchase-orders" ||
                        navigation.startsWith("/update-purchase-order/") ||
                        navigation.startsWith("/add-purchase-order")
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/purchase-orders" ||
                        navigation.startsWith("/update-purchase-order/") ||
                        navigation.startsWith("/add-purchase-order")
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>Purchase Orders</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/vendor-detail"
                      className={`nav-link ${navigation.startsWith("/vendor-detail/") ||
                        navigation === "/vendor-detail" ? "active" : ""
                        } `}
                    >
                      <i className={`${navigation.startsWith("/vendor-detail/") ||
                        navigation === "/vendor-detail"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p className="">Vendor Detail</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/vendors"
                      className={`nav-link ${navigation === "/vendors" ||
                        navigation === "/add-vendor" ||
                        navigation.startsWith("/update-vendor/")
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/vendors" ||
                        navigation === "/add-vendor" ||
                        navigation.startsWith("/update-vendor/")
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p className="">Vendors</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={`nav-item ${isInventoryMenuOpen ? "menu-open" : ""}`}>
                <Link
                  className={`nav-link ${navigation === "/items" ||
                    navigation === "/add-item" ||
                    navigation.startsWith("/update-item/") ||
                    navigation.startsWith("/item-detail/")
                    ? "active"
                    : ""
                    } `}
                  onClick={handleInventoryMenuToggle}
                >
                  <i className="fa-solid fa-warehouse me-3" />
                  <p className="">Inventory</p>
                  <i className="right fas fa-angle-right" />
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/item-detail"
                      className={`nav-link ${navigation.startsWith("/item-detail/") ? "active" : ""
                        } `}
                    >
                      <i className={`${navigation.startsWith("/item-detail/") || navigation === "/item-detail"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p className="">Item Detail</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/items"
                      className={`nav-link ${navigation === "/items" ||
                        navigation === "/add-item" ||
                        navigation.startsWith("/update-item/")
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/items" ||
                        navigation === "/add-item" ||
                        navigation.startsWith("/update-item/")
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p className="">Items</p>
                    </Link>
                  </li>
                </ul>

              </li>

              {/* <li className={`nav-item ${isFinanceMenuOpen ? "menu-open" : ""}`}>
                <Link
                  className={`nav-link `}
                  onClick={handleFinanceMenuToggle}
                >
                  <i className="fa-solid fa-sack-dollar me-3" />
                  <p className="">Finance</p>
                  <i className="right fas fa-angle-left" />
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/sales-invoices"
                      className={`nav-link `}
                    >
                      <i className="fa-regular fa-circle nav-icon me-3" />
                      <p className="">Sales Invoices</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to=""
                      className={`nav-link `}
                    >
                      <i className="fa-regular fa-circle nav-icon me-3" />
                      <p className="">Purchase Invoices</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to=""
                      className={`nav-link `}
                    >
                      <i className="fa-regular fa-circle nav-icon me-3" />
                      <p className="">Payments In</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to=""
                      className={`nav-link `}
                    >
                      <i className="fa-regular fa-circle nav-icon me-3" />
                      <p className="">Payments Out</p>
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li className={`nav-item ${isReportingMenuOpen ? "menu-open" : ""}`}>
                <Link
                  className={`nav-link ${navigation === "/van-stops" ||
                    navigation === "/pick-list" ||
                    navigation === "/inventory-activity" ||
                    navigation === "/customer-statement" ||
                    navigation === "/profit-cost" ||
                    navigation === "/vendor-statement" ||
                    navigation === "/inventory" ||
                    navigation === "/sales-transactions" ||
                    navigation === "/tax-report" ||
                    navigation === "/purchase-transactions"
                    ? "active"
                    : ""
                    } `}
                  onClick={handleReportingMenuToggle}
                >
                  <i className="fa-solid fa-file me-3" />
                  <p className="">Reporting</p>
                  <i className="right fas fa-angle-right" />
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/van-stops"
                      className={`nav-link ${navigation === "/van-stops"
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/van-stops"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>Van Stops</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/pick-list"
                      className={`nav-link ${navigation === "/pick-list"
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/pick-list"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>Pick List</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/inventory"
                      className={`nav-link ${navigation === "/inventory"
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/inventory"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>Inventory</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/inventory-activity"
                      className={`nav-link ${navigation === "/inventory-activity"
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/inventory-activity"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>Inventory Activity</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/profit-cost"
                      className={`nav-link ${navigation === "/profit-cost"
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/profit-cost"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>
                        Profit and Cost
                      </p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/customer-statements"
                      className={`nav-link ${navigation === "/customer-statements"
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/customer-statements"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p style={{textWrap: "nowrap"}}>
                        Customer Statements
                      </p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/vendor-statements"
                      className={`nav-link ${navigation === "/vendor-statements"
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/vendor-statements"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>
                        Vendor Statements
                      </p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/sales-transactions"
                      className={`nav-link ${navigation === "/sales-transactions"
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/sales-transactions"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p style={{textWrap: "nowrap"}}>
                        Sales Transactions
                      </p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/purchase-transactions"
                      className={`nav-link ${navigation === "/purchase-transactions"
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/purchase-transactions"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p style={{textWrap: "nowrap"}}>
                        Purchase Transactions
                      </p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/tax-report"
                      className={`nav-link ${navigation === "/tax-report"
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/tax-report"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>
                        Tax Report
                      </p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={`nav-item ${isSetupMenuOpen ? "menu-open" : ""}`}>
                <Link
                  className={`nav-link ${navigation === "/unitTypes" ||
                    navigation === "/taxes" ||
                    navigation === "/add-tax" ||
                    navigation === "/vanstops" ||
                    navigation.startsWith("/update-tax/") ||
                    navigation.startsWith("/tax-detail/")
                    ? "active"
                    : ""
                    } `}
                  onClick={handleSetupMenuToggle}
                >
                  <i className="fa-solid fa-gear me-3" />
                  <p className=""> Setup</p>
                  <i className="right fas fa-angle-right" />
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/vanstops"
                      className={`nav-link ${navigation === "/vanstops" ||
                        navigation === "/add-vehicle" ||
                        navigation.startsWith("/update-vehicle/") ||
                        navigation.startsWith("/vehicle-detail/")
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className="fa-solid fa-truck-moving me-3" />
                      <p className="">Van Stops</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/taxes"
                      className={`nav-link ${navigation === "/taxes" ||
                        navigation === "/add-tax" ||
                        navigation.startsWith("/update-tax/") ||
                        navigation.startsWith("/tax-detail/")
                        ? "active"
                        : ""
                        } `}
                    >
                      <i className={`${navigation === "/taxes" ||
                        navigation === "/add-tax" ||
                        navigation.startsWith("/update-tax/") ||
                        navigation.startsWith("/tax-detail/")
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>Taxes</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/unitTypes"
                      className={`nav-link ${navigation === "/unitTypes" ? "active" : ""
                        } `}
                    >
                      <i className={`${navigation === "/unitTypes"
                        ? "far fa-circle-check nav-icon me-3"
                        : "far fa-circle nav-icon me-3"
                        } `} />
                      <p>Unit Types</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/item-categories"
                      className={`nav-link ${navigation === "/item-categories" ? "active" : ""
                        } `}
                    >
                      <i className="fa-solid fa-list me-3"></i>
                      <p className="">Item Categories</p>
                    </Link>
                  </li>
                </ul>
              </li>

              {userRole === "1" && (
                <li className={`nav-item ${isMenuOpen ? "menu-open" : ""}`}>
                  <Link
                    className={`nav-link ${navigation === "/users" ||
                      navigation === "/add-user" ||
                      navigation === "/update-user"
                      ? "active"
                      : ""
                      } `}
                    onClick={handleMenuToggle}
                  >
                    <i className="fa-solid fa-user me-3" />
                    <p className="">Admin</p>
                    <i className="right fas fa-angle-right" />
                  </Link>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/users"
                        className={`nav-link ${navigation === "/users" ||
                          navigation === "/add-user" ||
                          navigation === "/update-user"
                          ? "active"
                          : ""
                          } `}
                      >
                        <i className={`${navigation === "/users" ||
                          navigation === "/add-user" ||
                          navigation === "/update-user"
                          ? "far fa-circle-check nav-icon me-3"
                          : "far fa-circle nav-icon me-3"
                          } `} />
                        <p>Users</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </aside>

        <section className="content-wrapper">
          <div className="container-fluid px-4">
            <div className="row px-2">{children}</div>
          </div>
        </section>

        <aside className="control-sidebar control-sidebar-dark"></aside>
      </div>

      <CustomModal
        visible={visible}
        title="Log Out Confirmation"
        message="Are you sure you want to log out?"
        onCancel={handleModelCancelButton}
        onConfirm={handleLogout}
      />
    </>
  );
};
export default AppLayout;
