import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerComponent = ({ selectedDate, onChange, minDate }) => {
    return (
        <div className="date-picker-container">
            <DatePicker
                selected={selectedDate}
                onChange={onChange}
                minDate={minDate}
                placeholderText="mm-dd-yyyy"
                dateFormat="MM-dd-yyyy"
                className="form-control"
            />
            <i className="fa fa-calendar date-picker-icon" />
        </div>
    );
};

export default DatePickerComponent;