import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppLayout from "../Component/Layout/AppLayout";

const Protected = (props) => {
  const { Component, navigation } = props;
  const navigate = useNavigate();
  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/");
    }
  });
  return (
    <AppLayout navigation={navigation} >
      <Component />
    </AppLayout>
  );
};
Protected.propTypes = {
  Component: PropTypes.elementType.isRequired,
};
export default Protected;
