import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesConfig from './Route/Route';
import Lodder from "./Component/Loader";
import useInactivityTimer from "./Route/InavctivityTimer";
const App = () => {
  useInactivityTimer();
  return (
    <Router>
      <Suspense fallback={<Lodder show={true} />}>
        <RoutesConfig />
      </Suspense>
    </Router>
  );
}

export default App;
