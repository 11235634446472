import api from "../Configuration/ApiConfig";

const LoginService = {
    Login: (loginCredentials) => {
        const request = api.post('Auth/Login', loginCredentials)
        return request.then((response) => {
            return response.data
        })
    },

    ForgotPassword:(email)=>{
        const request = api.post('Auth/ForgotPassword?email='+email)
        return request.then((response)=>{
            return response.data
        })
    },
    ResetPassword:(resetPassword)=>{
        const request = api.put('Auth/ResetPassword', resetPassword)
        return request.then((response)=>{
            return response.data
        })
     },
}
export default LoginService;