import { useEffect, useRef } from 'react';

const useInactivityTimer = (timeout = 15 * 60 * 1000) => { // 15 minutes
    const timeoutRef = useRef(null);

    const handleActivity = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            // Redirect to login page or perform logout
            localStorage.removeItem('accessToken')
            window.location.href = '/'
            // Adjust based on your routes
        }, timeout);
    };

    useEffect(() => {
        // Set up event listeners for user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);
        window.addEventListener('click', handleActivity);
        window.addEventListener('scroll', handleActivity);

        return () => {
            // Clean up event listeners on component unmount
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            window.removeEventListener('click', handleActivity);
            window.removeEventListener('scroll', handleActivity);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);
};

export default useInactivityTimer;
