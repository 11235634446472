import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Protected from "./Protected";
import LoginForm from "../Component/PublicPages/Login";
import ForgotPassword from "../Component/PublicPages/ForgotPassword";
import ResetPassword from "../Component/PublicPages/ResetPassword";
import SingUp from "../Component/PublicPages/SignUp";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "../Component/Error";
import MonthlyInvoices from "../Component/Reporting/MonthlyInvoices";
const Dashboard = lazy(() => import("../Component/Dashboard/Dashboard"));
const Home = lazy(() => import("../Component/Dashboard/Home"));
const UserList = lazy(() => import("../Component/Users/UserList"));
const Profile = lazy(() => import("../Component/Users/Profile"));
const AddUpdateItem = lazy(() => import("../Component/Item/AddUpdateItem"));
const ItemList = lazy(() => import("../Component/Item/ItemList"));
const ItemDetail = lazy(() => import("../Component/Item/ItemDetail"));
const AddUpdateUser = lazy(() => import("../Component/Users/AddUpdateUser"));
const AddUpdateVendor = lazy(() => import("../Component/Vendor/AddUpdateVendor"));
const VendorList = lazy(() => import("../Component/Vendor/VendorList"));
const VendorDetail = lazy(() => import("../Component/Vendor/VendorDetail"));
const CustomerList = lazy(() => import("../Component/Customer/CustomerList"));
const AddUpdateCustomer = lazy(() => import("../Component/Customer/AddUpdateCustomer"));
const AddUpdateVehicle = lazy(() => import("../Component/Vehicle/AddUpdateVehicle"));
const VehicleList = lazy(() => import("../Component/Vehicle/VehicleList"));
const CustomerDetail = lazy(() => import("../Component/Customer/CustomerDetail"));
const UnitType = lazy(() => import("../Component/UnitType/UnitTypeList"));
const TaxList = lazy(() => import("../Component/Tax/TaxList"));
const AddUpdateTax = lazy(() => import("../Component/Tax/AddUpdateTax"));
const TaxDetail = lazy(() => import("../Component/Tax/TaxDetail"));
const ItemCategoryList = lazy(() => import("../Component/ItemCategory/ItemCategoryList"));
const PurchaseOrderList = lazy(() => import("../Component/PurchaseOrder/PurchaseOrderList"));
const AddUpdatePurchaseOrder = lazy(() => import("../Component/PurchaseOrder/AddUpdatePurchaseOrder"));
const SalesOrderList = lazy(() => import("../Component/SalesOrder/SalesOrderList"));
const AddUpdateSalesOrder = lazy(() => import("../Component/SalesOrder/AddUpdateSalesOrder"));
const PurchaseOrderDetail = lazy(() => import("../Component/PurchaseOrder/PurchaseOrderDetail"));
const SalesOrderDetail = lazy(() => import("../Component/SalesOrder/SalesOrderDetail"));
const SalesInvoiceList = lazy(() => import("../Component/SalesInvoice/SalesInvoiceList"));
const SalesInvoice = lazy(() => import("../Component/SalesInvoice/SalesOrderInvoice"));
const VanStops = lazy(() => import("../Component/Reporting/VanStops"));
const PickList = lazy(() => import("../Component/Reporting/PickList"));
const Inventory = lazy(() => import("../Component/Reporting/Inventory"));
const Invoices = lazy(() => import("../Component/Reporting/MonthlyInvoices"));
const CustomerStatement = lazy(() => import("../Component/Reporting/CustomerStatement"));
const VendorStatement = lazy(() => import("../Component/Reporting/VendorInvoice"));
const SalesTransactions = lazy(() => import("../Component/Reporting/SalesTransaction"));
const InventoryReport = lazy(() => import("../Component/Reporting/InventoryReport"));
const TaxReport = lazy(() => import("../Component/Reporting/TaxReport"));
const PurchaseTransactions = lazy(() => import("../Component/Reporting/PurchaseTransaction"));

const RoutesConfig = () => {

    return (
        <ErrorBoundary FallbackComponent={Fallback}>

            <Routes>
                <Route exact path="/" element={<LoginForm />} />
                <Route exact path="/forgotpassword" element={<ForgotPassword />} />
                <Route exact path="/resetpassword/:userId" element={<ResetPassword />} />
                <Route exact path="/SignIn/:id" element={<SingUp />} />
                <Route
                    exact
                    path="/dashboard"
                    element={<Protected Component={Dashboard} navigation={'dashboard'} />}
                />
                <Route
                    exact
                    path="/home"
                    element={<Protected Component={Home} navigation={'home'} />}
                />
                <Route
                    exact
                    path="/users"
                    element={<Protected Component={UserList} navigation={'users'} />}
                />
                <Route
                    exact
                    path="/profile"
                    element={<Protected Component={Profile} navigation={'profile'} />}
                />
                <Route
                    exact
                    path="/items"
                    element={<Protected Component={ItemList} navigation={'items'} />}
                />
                <Route
                    exact
                    path="/add-item"
                    element={<Protected Component={AddUpdateItem} navigation={'/add-item'} />}
                />
                <Route
                    exact
                    path="/update-item/:id"
                    element={<Protected Component={AddUpdateItem} navigation={'/update-item'} />}
                />
                <Route
                    exact
                    path="/item-detail/:id"
                    element={<Protected Component={ItemDetail} navigation={'/item-detail'} />}
                />
                <Route
                    exact
                    path="/item-detail"
                    element={<Protected Component={ItemDetail} navigation={'/item-detail'} />}
                />
                <Route
                    exact
                    path="/add-user"
                    element={<Protected Component={AddUpdateUser} navigation={'/add-user'} />}
                />
                <Route
                    exact
                    path="/update-user"
                    element={<Protected Component={AddUpdateUser} navigation={'/update-user'} />}
                />
                  <Route
                    exact
                    path="/vendors"
                    element={<Protected Component={VendorList} navigation={'vendors'} />}
                />
                <Route
                    exact
                    path="/add-vendor"
                    element={<Protected Component={AddUpdateVendor} navigation={'/add-vendor'} />}
                />
                <Route
                    exact
                    path="/update-vendor/:id"
                    element={<Protected Component={AddUpdateVendor} navigation={'/update-vendor'} />}
                />
                <Route
                    exact
                    path="/vendor-detail/:id"
                    element={<Protected Component={VendorDetail} navigation={'/vendor-detail'} />}
                />
                 <Route
                    exact
                    path="/vendor-detail"
                    element={<Protected Component={VendorDetail} navigation={'/vendor-detail'} />}
                />
                  <Route
                    exact
                    path="/vendor-detail"
                    element={<Protected Component={VendorDetail} navigation={'/vendor-detail'} />}
                />
                <Route
                    exact
                    path="/customers"
                    element={<Protected Component={CustomerList} navigation={'customers'} />}
                />
                <Route
                    exact
                    path="/add-customer"
                    element={<Protected Component={AddUpdateCustomer} navigation={'/add-customer'} />}
                />
                <Route
                    exact
                    path="/update-customer/:id"
                    element={<Protected Component={AddUpdateCustomer} navigation={'/update-customer/:id'} />}
                />
                <Route
                    exact
                    path="/customer-detail/:customerId"
                    element={<Protected Component={CustomerDetail} navigation={'/customer-detail/:customerId'} />}
                />
                <Route
                    exact
                    path="/customer-detail"
                    element={<Protected Component={CustomerDetail} navigation={'/customer-detail/:customerId'} />}
                />
                 <Route
                    exact
                    path="/unitTypes"
                    element={<Protected Component={UnitType} navigation={'unitTypes'} />}
                />
                 <Route
                    exact
                    path="/taxes"
                    element={<Protected Component={TaxList} navigation={'taxes'} />}
                />
                <Route
                    exact
                    path="/add-tax"
                    element={<Protected Component={AddUpdateTax} navigation={'/add-tax'} />}
                />
                <Route
                    exact
                    path="/update-tax/:id"
                    element={<Protected Component={AddUpdateTax} navigation={'/update-tax'} />}
                />
                <Route
                    exact
                    path="/tax-detail/:id"
                    element={<Protected Component={TaxDetail} navigation={'/tax-detail'} />}
                />
                 <Route
                    exact
                    path="/vanstops"
                    element={<Protected Component={VehicleList} navigation={'vanstops'} />}
                />
                <Route
                    exact
                    path="/add-vehicle"
                    element={<Protected Component={AddUpdateVehicle} navigation={'/add-vehicle'} />}
                />
                <Route
                    exact
                    path="/update-vehicle/:id"
                    element={<Protected Component={AddUpdateVehicle} navigation={'/update-vehicle'} />}
                />
                <Route
                    exact
                    path="/item-categories"
                    element={<Protected Component={ItemCategoryList} navigation={'/item-categories'} />}
                />
                <Route
                    exact
                    path="/purchase-orders"
                    element={<Protected Component={PurchaseOrderList} navigation={'/purchase-orders'} />}
                />
                <Route
                    exact
                    path="/add-purchase-order"
                    element={<Protected Component={AddUpdatePurchaseOrder} navigation={'/add-purchase-order'} />}
                />
                <Route
                    exact
                    path="/update-purchase-order/:id"
                    element={<Protected Component={AddUpdatePurchaseOrder} navigation={'/update-purchase-order'} />}
                />
                  <Route
                    exact
                    path="/invoices"
                    element={<Protected Component={SalesOrderList} navigation={'/invoices'} />}
                />
                <Route
                    exact
                    path="/add-invoice"
                    element={<Protected Component={AddUpdateSalesOrder} navigation={'/add-invoice'} />}
                />
                <Route
                    exact
                    path="/update-invoice/:id"
                    element={<Protected Component={AddUpdateSalesOrder} navigation={'/update-invoice'} />}
                />
                <Route
                    exact
                    path="/purchase-order-detail/:id"
                    element={<Protected Component={PurchaseOrderDetail} navigation={'/purchase-order-detail'} />}
                />
                <Route
                    exact
                    path="/purchase-order-detail"
                    element={<Protected Component={PurchaseOrderDetail} navigation={'/purchase-order-detail'} />}
                />
                <Route
                    exact
                    path="/invoice-detail/:id"
                    element={<Protected Component={SalesOrderDetail} navigation={'/invoice-detail'} />}
                />
                <Route
                    exact
                    path="/invoice-detail"
                    element={<Protected Component={SalesOrderDetail} navigation={'/invoice-detail'} />}
                />
                 <Route
                    exact
                    path="/sales-invoices"
                    element={<Protected Component={SalesInvoiceList} navigation={'/sales-invoices'} />}
                />
                <Route
                    exact
                    path="/sales-invoice/:id"
                    element={<Protected Component={SalesInvoice} navigation={'/sales-invoice'} />}
                />
                <Route
                    exact
                    path="/van-stops"
                    element={<Protected Component={VanStops} navigation={'/van-stops'} />}
                />
                <Route
                    exact
                    path="/pick-list"
                    element={<Protected Component={PickList} navigation={'/pick-list'} />}
                />
                 <Route
                    exact
                    path="/inventory-activity"
                    element={<Protected Component={Inventory} navigation={'/inventory-activity'} />}
                />
                <Route
                    exact
                    path="/profit-cost"
                    element={<Protected Component={Invoices} navigation={'/profit-cost'} />}
                />
                <Route
                    exact
                    path="/customer-statements"
                    element={<Protected Component={CustomerStatement} navigation={'/customer-statements'} />}
                />
                <Route
                    exact
                    path="/vendor-statements"
                    element={<Protected Component={VendorStatement} navigation={'/vendor-statements'} />}
                />
                <Route
                    exact
                    path="/sales-transactions"
                    element={<Protected Component={SalesTransactions} navigation={'/sales-transactions'} />}
                />
                <Route
                    exact
                    path="/inventory"
                    element={<Protected Component={InventoryReport} navigation={'/inventory'} />}
                />
                <Route
                    exact
                    path="/tax-report"
                    element={<Protected Component={TaxReport} navigation={'/tax-report'} />}
                />
                <Route
                    exact
                    path="/purchase-transactions"
                    element={<Protected Component={PurchaseTransactions} navigation={'/purchase-transactions'} />}
                />
                <Route path="*" element={<LoginForm />} />
            </Routes>
        </ErrorBoundary>
    );
};

export default RoutesConfig;